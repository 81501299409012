import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const AsyncRank = asyncComponent(() => import("./containers/rank"));
const AsyncProfile = asyncComponent(() => import("./containers/profile"));
const AsyncNotFound = asyncComponent(() => import("./containers/notfound"));

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute
      path="/profile/:name/:month?/:year?"
      exact
      component={AsyncProfile}
      props={childProps}
    />
    <AppliedRoute
      path="/:month?/:year?"
      exact
      component={AsyncRank}
      props={childProps}
    />    
    {/* Finally, catch all unmatched routes */}
    <Route component={AsyncNotFound} />
  </Switch>
;