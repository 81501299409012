import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Routes from "./routes";
import './styles/css/App.css';
import './styles/css/adminLTE.css';
import './styles/css/skin.css';


class App extends Component {
  render() {
    return (

      <Routes />


    );
  }
}
export default withRouter(App);
