import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import "./web.config";
import './styles/css/index.css';
import '../src/components/bootstrap/dist/css/bootstrap.css';
import '../src/components/font-awesome/css/font-awesome.min.css';
import '../src/components/Ionicons/css/ionicons.min.css';
import '../src/styles/css/skin.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(  <Router>
    <App />
  </Router>,
  document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
